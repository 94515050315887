<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="Profile"
          :breadcrumb="[
            {
              label: 'Dashboard',
            },
            { label: '  Profile' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-5 col-md-5 col-12" style="padding:5px 5px 5px 5px">
            <div
              class="card custom-card"
              style="
              
                height: 100%;
              "
            >
              <div class="card-body pt-2">
                <div class="row">
                  <div class="col">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder"
                        >Master Distributer Profile</b
                      >
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder"
                        >Master Distributer Profile</b
                      >
                    </h4>
                  </div>
                </div>

                <div class="row">
                  <!-- img responsive mobile  -->
                  <div
                    class="col-sm-12 col-12 d-block d-md-none d-xl-none d-lg-none text-center mb-1"
                  >
                    <img
                      style="width: 40%; border-radius: 15px"
                      :src="profileImg"
                      alt=""
                    />
                    <br />
                    <span style="color: #00364f">
                      <p
                        v-if="masterDistributer.loginStatus == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                  <h5 class="text-start">
                    <b style="color: #f21300; font-weight: bolder">Personal Details </b>
                  </h5>

                  <div class="col-xl-9 col-md-9 col-lg-9 col-sm-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Code : </b>
                      <span v-if="masterDistributer">{{ masterDistributer.code }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Name : </b>
                      <span v-if="masterDistributer">{{ masterDistributer.name }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>DOB : </b>
                      <!-- <span v-if="masterDistributer">{{ masterDistributer.dob }} </span> -->
                      <span v-if="masterDistributer">
                        {{ dateTime(masterDistributer.dob) }}
                      </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Gender : </b>
                      <span v-if="masterDistributer"
                        >{{ masterDistributer.gender }}
                      </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Pan No : </b>
                      <span v-if="masterDistributer.master_distributer_kyc"
                        >{{ masterDistributer.master_distributer_kyc.panNo }}
                      </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Aadhar No : </b>
                      <span v-if="masterDistributer.master_distributer_kyc"
                        >{{ masterDistributer.master_distributer_kyc.aadharNo }}
                      </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Phone Number : </b>
                      <span v-if="masterDistributer"
                        >+91-{{ masterDistributer.phone }}</span
                      >
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Email Id : </b>
                      <span v-if="masterDistributer">{{ masterDistributer.email }}</span>
                    </div>
                  </div>
                  <!-- img responsive Pc  -->
                  <div
                    class="col-xl-3 col-md-3 col-lg-3 d-none d-xl-block d-md-block d-lg-block text-center"
                  >
                    <img
                      style="width: 100%; border-radius: 15px; margin-top: -20px"
                      :src="profileImg"
                      alt=""
                    />
                    <span style="color: #00364f; width: 40%">
                      <p
                        v-if="masterDistributer.loginStatus == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                </div>
                <div class="col-12" style="margin-left: 7px">
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Address : </b>
                    <span v-if="masterDistributer">{{ masterDistributer.address }} {{ masterDistributer.city }} {{ masterDistributer.pin }}</span>
                  </div>

                  <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>State : </b>
                      <span v-if="masterDistributer.state">{{
                        masterDistributer.state.name
                      }}</span>
                    </div>
                </div>
                     <!-- bussiness Detais  -->
                     <div class="row">
                  <h5 class="text-start mt-2">
                    <b style="color: #f21300; font-weight: bolder">Business Details </b>
                  </h5>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Firm Name: </b>
                      <span v-if="masterDistributer"
                        >{{ masterDistributer.bussinessName }}
                      </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Bussiness Pan: </b>
                      <span v-if="masterDistributer"
                        >{{ masterDistributer.bussinessPan }}
                      </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>DOI: </b>
                      <span v-if="masterDistributer"
                        >{{ dateTime(masterDistributer.doi) }}
                      </span>
                    </div>
                    <!-- <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Specialization: </b>
                      <span v-if="masterDistributer"
                        >{{ masterDistributer.specialization }}
                      </span>
                    </div> -->

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Business Address : </b>
                      <span v-if="masterDistributer"
                        >{{ masterDistributer.bussinessAddress }},{{ masterDistributer.bussinessCity}} ,{{ masterDistributer.bussinessPin }}
                      </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Business State : </b>
                      <span v-if="masterDistributer.business_state">{{
                        masterDistributer.business_state.name
                      }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>GST Number : </b>
                      <span v-if="masterDistributer">{{ masterDistributer.gstin }} </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>CIN : </b>
                      <span v-if="masterDistributer">{{ masterDistributer.cin }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>TAN : </b>
                      <span v-if="masterDistributer">{{ masterDistributer.tds }} </span>
                    </div>

                  </div>
                </div>
                <!-- end  bussiness Detais  -->

                <div class="row mt-1">
                  <div class="col-12 text-end">
                    <button
                      @click="valueAssingForProfile()"
                      type="button"
                      class="btn text-white btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#profileUpdateModel"
                      style="background-color: #00364f; margin-right: 5px"
                    >
                      <b
                        >Edit Profile &nbsp;
                        <i class="fa fa-edit"></i>
                      </b>
                    </button>
                    <button
                    @click="valueAssingForProfile()"
                      type="button"
                      class="btn text-white btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#doccuments"
                      style="background-color: #f21300; margin-right: 5px"
                    >
                      <b
                        >Kyc Doccuments &nbsp;
                        <i class="fa fa-hand-pointer-o"></i>
                      </b>
                    </button>
                    <!-- <button type="button" class="btn btns text-white btn-sm"><b>Edit Profile &nbsp;
                        <font-awesome-icon icon="edit" />
                      </b></button> -->
                  </div>
                </div>

                <div
                  class="row mt-1"
                  style="background-color: #e3e3e3; border-radius: 15px"
                >
                  <div class="col-12 p-1">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>IP Address :</b>
                      <span v-if="masterDistributer">{{
                        masterDistributer.ipAddress
                      }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Last Login :</b>
                      <span v-if="masterDistributer">{{
                        dateTimeWithTime(masterDistributer.last_login)  
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-7 col-lg-7 col-md-7 col-12" style="padding:5px 5px 5px 5px">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card"
              style="height: 100%"
            >
              <div class="card-body">
                <div class="row" v-if="wallet.id">
                  <div class="col-12">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder">Wallet</b>
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder">Wallet</b>
                    </h4>
                  </div>

                  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Wallet Balance : </b>
                      <span v-if="wallet.id">Rs.{{ wallet.balanceAmount }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Virtual Account : </b>
                      <span v-if="wallet.id">{{ wallet.merchant_vpa }} </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Wallet Code : </b>
                      <span v-if="wallet.id">{{ wallet.wallet }} </span>
                    </div>

                    <span style="color: #00364f" v-if="wallet.id">
                      <p
                        v-if="wallet.status == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                </div>
                <div class="row" v-else>
                  <div class="col">
                    <button
                    @click="walletCreateNullForm()"
                      type="button"
                      class="btn text-white btn-sm"
                      style="
                        background-color: #f21300;
                        font-size: 13px;
                        letter-spacing: 1px;
                      "
                      data-bs-toggle="modal"
                      data-bs-target="#walletModel"
                    >
                      <b> <font-awesome-icon icon="plus" /> Create Wallet </b>
                    </button>
                  </div>
                </div>

                <hr v-if="wallet.id" style="color: #e3e3e3" />
                <div class="row" v-if="wallet.id">
                  <div class="col-xl-6 col-md-6 col-lg-6 col-6 text-start" style="padding:5px 5px 5px 0px">
                    <h4><b style="color: #00364f; font-weight: bolder">Withdrawls</b></h4>
                  </div>
                  <div class="col-xl-6 col-md-6 col-lg-6 col-6 text-end" style="padding:5px 0px 5px 5px">
              
                      <b style="color: #00364f; font-weight: bolder"
                        ><button
                        @click="withdrawalFormNull()"
                          type="button"
                          class="btn text-white btn-sm"
                          style="
                            background-color: #f21300;
                            font-size: 13px;
                            letter-spacing: 1px;
                          "
                          data-bs-toggle="modal"
                          data-bs-target="#walletWithDrawModel"
                        >
                          <b> <font-awesome-icon icon="plus" /> Withdrawls </b>
                        </button></b
                      >

                      &nbsp;
                    <router-link
                        style="
                        color: #ffff;
                            background-color: #00364f;
                            font-size: 13px;
                            letter-spacing: 1px;
                          "
                        to="/master-distributer/wallet-Withdraw-Req"
                        type="button"
                        class="btn btn-sm my-btn"
                      >
                        <small > View All </small>
                       
                      </router-link>
               
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="container-fluid table-scroll">
                      <table
                        class="table table-hover table-sm text"
                        style="font-size: 14px"
                      >
                        <tr class="tr-head table-wrapper-scroll-y rounded-circle">
                          <!-- <th></th> -->
                          <th class="text-truncate">+</th>
                          <th class="text-truncate">Amount</th>
                          <th class="text-truncate">Ac No.</th>
                          <th class="text-truncate">UPI</th>
                          <th class="text-truncate">Date</th>
                          <th class="text-truncate">Payment Mode</th>

                          <th class="text-truncate">Status</th>
                          <th class="text-truncate">Action</th>
                        </tr>

                        <tbody>
                          <tr
                            v-for="(walletWithdrawReq, index) in walletwithdrawalsReq"
                            :key="index"
                          >
                            <td>
                              <img
                                style="width: 8px; margin-right: 5px"
                                src="/assets/image/record.png"
                                alt=""
                              />
                            </td>

                            <td class="text-truncate" v-if="walletWithdrawReq">
                              {{ walletWithdrawReq.amount }}
                            </td>
                            <td class="text-truncate">
                              <span v-if="walletWithdrawReq.beneficiaryaccount">
                                {{ walletWithdrawReq.beneficiaryaccount.account_number }}
                              </span>

                              <span v-else> NA </span>
                            </td>
                            <td class="text-truncate">
                              <span v-if="walletWithdrawReq.merchant_vpa">
                                {{ walletWithdrawReq.merchant_vpa }}
                              </span>
                              <span v-else> NA </span>
                            </td>
                            <td class="text-truncate" v-if="walletWithdrawReq">
                              {{ dateTime(walletWithdrawReq.date) }}
                            </td>
                            <td class="text-truncate" v-if="walletWithdrawReq">
                              {{ walletWithdrawReq.paymentMode }}
                            </td>

                            <td class="text-truncate" v-if="walletWithdrawReq">
                                <p
                                  v-if="walletWithdrawReq.status == 'complete'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: green;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> {{ walletWithdrawReq.status }}</b>
                                </p>
                                <p
                                  v-if="walletWithdrawReq.status == 'cancel'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: #f21000;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> {{ walletWithdrawReq.status }}</b>
                                </p>

                                <p
                                  v-if="walletWithdrawReq.status == 'pending'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: #fc7f03;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> {{ walletWithdrawReq.status }}</b>
                                </p>
                                <p
                                  v-if="walletWithdrawReq.status == 'reject'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: black;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> {{ walletWithdrawReq.status }}</b>
                                </p>
                              </td>
                            <td class="text-truncate text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <!-- <button
                                  @click="walletWithdrawReqCancel(walletWithdrawReq)"
                                  type="button"
                                  class="btn btns btn-sm btn-danger"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="remove" />
                                </button> -->

                                <button
                                    @click="walletWithdrawReqCancel(walletWithdrawReq)"
                                    class="btn btn-sm text-white"
                                    style="
                                      padding: 5px 5px 5px 5px;
                                      background-color: #f21000;
                                    "
                                  >
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                  </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <hr v-if="wallet.id" style="color: #e3e3e3" />

                <!-- start list area  -->
                <div v-if="wallet.id" class="row mt-3">
                  <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-start">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder"
                        >Beneficiary Account</b
                      >
                    </h4>
                  </div>
                  <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-end">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder"
                        ><button
                        @click="formnull()"
                          type="button"
                          class="btn text-white btn-sm"
                          style="
                            background-color: #f21300;
                            font-size: 13px;
                            letter-spacing: 1px;
                            box-shadow: 2px 2px 2px gray;
                          "
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <b> <font-awesome-icon icon="plus" /> Bank Account </b>
                        </button></b
                      >
                    </h4>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="container-fluid table-scroll">
                      <table
                        class="table table-hover table-sm text"
                        style="font-size: 14px"
                      >
                        <tr class="tr-head table-wrapper-scroll-y rounded-circle">
                          <!-- <th></th> -->
                          <th class="text-truncate">+</th>
                          <th class="text-truncate">Ac. Holder</th>
                          <th class="text-truncate">Ac No.</th>
                          <th class="text-truncate">Ifsc</th>
                          <th class="text-truncate">Account Type</th>
                          <th class="text-truncate">Bank</th>
                          <th class="text-truncate">Mobile No</th>
                          <th class="text-truncate">Status</th>
                          <!-- <th class="text-truncate">Action</th> -->
                        </tr>

                        <tbody>
                          <tr
                            v-for="(beneficiaryaccount, index) in beneficiaryaccounts"
                            :key="index"
                          >
                            <td>
                              <img
                                style="width: 8px; margin-right: 5px"
                                src="/assets/image/record.png"
                                alt=""
                              />
                            </td>

                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.account_holder_name }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.account_number }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.account_type }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.ifsc_code }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.bank_name }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.mobile }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              <p v-if="beneficiaryaccount.status == 'true'" class="text-white btn btn-sm" style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                ">
                                <b> Success</b>
                              </p>
                              <p v-else class="text-white btn btn-sm" style="
                                  background-color: red;
                                  width: 80px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                ">
                                <b> Deactivate</b>
                              </p>
                            </td>
                            <!-- <td class="text-truncate text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <button
                                  @click="editBeneficiaryAcount(beneficiaryaccount)"
                                  type="button"
                                  class="btn btns btn-sm btn-success"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>
                              </div>
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
  <!--start model  -->

  <!--start model  -->
  <div class="modal fade" id="exampleModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-white">
          <h4 class="modal-title" id="exampleModalLabel" style="color: #00364f;">
            <b>Beneficiary Account </b>
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="
            edit == 'false' ? beneficiaryaccountAdd() : updatebeneficiaryAcount()
            ">
            <div class="row">
              <div class="col-12" v-if="bankVerify">
             
                  <div v-if="bankVerify.message == 'Bank Account details verified successfully.'" class="alert p-1 alert-success" role="alert">
                    {{ bankVerify.message }}
                  </div>
                
             <span v-if="bankVerify.message">
  <div  v-if="bankVerify.message != 'Bank Account details verified successfully.'"  class="alert alert-danger p-1" role="alert">
                    {{ bankVerify.message }}
                  </div>
             </span >
                
           
              </div>
              <div class="col-12" v-if="form.errors">
                <div v-if="form.errors.message">
                  <div class="alert alert-danger" role="alert">
                    {{ form.errors.message }}
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Account Holder Name <span class="text-danger">*</span></label>
                <div class="from-group" v-if="bankVerify">
                  <input v-if="bankVerify.message == 'Bank Account details verified successfully.'" type="text" class="form-control" v-model="form.account_holder_name" style="height: 30px"
                    placeholder="Account Holder" disabled />
                    <input v-else required type="text" class="form-control" style="height: 30px" v-model="form.account_holder_name"
                    placeholder="Account Holder" @change="benificairyvalidation($event, 'AccountHolderName')" />
              
                </div>
              
                <span style="color: #f21000" id="AccountHolderName"></span>
                <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                      v-if="form.errors.error.account_holder_name">{{
                        form.errors.error.account_holder_name[0]
                      }}</span></span></small>
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Account Number <span class="text-danger">*</span></label>
                <div class="from-group" v-if="bankVerify">
                  <input v-if="bankVerify.message == 'Bank Account details verified successfully.'" type="text" v-model="form.account_number" class="form-control" style="height: 30px"
                    placeholder="Account Number" disabled />
                    <input v-else required type="text" v-model="form.account_number" class="form-control" style="height: 30px"
                    placeholder="Account Number" @change="benificairyvalidation($event, 'AccountNumber')" />
               
                </div>
              
                <span style="color: #f21000" id="AccountNumber"></span>
                <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                      v-if="form.errors.error.account_number">{{
                        form.errors.error.account_number[0]
                      }}</span></span></small>
              </div>



              <div class="c col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>IFSC <span class="text-danger">*</span></label>
                <div class="from-group" v-if="bankVerify">
                  <input v-if="bankVerify.message == 'Bank Account details verified successfully.'" type="text" v-model="form.ifsc_code" class="form-control" style="height: 30px"
                    placeholder="IFSC " disabled />
                    <input v-else required type="text" v-model="form.ifsc_code" class="form-control" style="height: 30px"
                    placeholder="IFSC " @change="benificairyvalidation($event, 'ifscNum')" />
                
                </div>
           
                <span style="color: #f21000" id="ifscNum"></span>
                <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                      v-if="form.errors.error.ifsc_code">{{
                        form.errors.error.ifsc_code[0]
                      }}</span></span></small>
              </div>

              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Phone Number <span class="text-danger">*</span></label>
                <div class="from-group" v-if="bankVerify">
                  <input v-if="bankVerify.message == 'Bank Account details verified successfully.'" type="number" v-model="form.mobile" class="form-control" placeholder=" Mobile Number"
                    style="height: 30px" disabled />
                    <input v-else required type="number" v-model="form.mobile" class="form-control" style="height: 30px"
                    placeholder=" Mobile Number" @change="benificairyvalidation($event, 'mobileNo')" />
              
                </div>
              
                <span style="color: #f21000" id="mobileNo"></span>

                <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                      v-if="form.errors.error.mobile">{{
                        form.errors.error.mobile[0]
                      }}</span></span></small>
              </div>

              






              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Bank Name <span class="text-danger">*</span></label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input type="text" v-model="form.bank_name" class="form-control" style="height: 30px"
                    placeholder="Bank Name" disabled />
                </div>
                <div class="from-group" v-else>
                  <input required type="text" v-model="form.bank_name" class="form-control" style="height: 30px"
                    placeholder="Bank Name" @change="benificairyvalidation($event, 'BankName')" />
                </div>
                <span style="color: #f21000" id="BankName"></span>
                <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                      v-if="form.errors.error.bank_name">{{
                        form.errors.error.bank_name[0]
                      }}</span></span></small>
              </div>







             

              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Account type <span class="text-danger">*</span></label>
                <div class="from-group">
                  <select class="form-select" v-model="form.account_type" aria-label="Default select example"
                    placeholder="Status" style="height: 30px; padding: 5px 5px 5px 5px">
                    <option value="savings" selected>Savings </option>
                    <option value="superAdminBeneficiaryAccount">Current </option>
                  </select>
                </div>
              </div>


            </div>

            


            <div class="modal-footer">
              <button type="button" class="btn btn-sm text-white" style="
                  background-color: #f21300;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                " data-bs-dismiss="modal">
                <b>Cancel</b>
              </button>
              <span v-if="bankVerify">
    <button v-if="bankVerify.message == 'Bank Account details verified successfully.'" type="submit" class="btn text-white btn-sm" style="
                  background-color: #00364f;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                ">
                <b>Confirm</b>
              </button> 
               <button v-else @click="benificiaryVerify()" type="button" class="btn btn-success btn-sm text-white"><strong>
    Verify
  </strong></button>
              </span>
          
            
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->
  <!-- end  modal -->
  <!-- Modal kyc doccuments show  -->
  <div
    class="modal fade"
    id="doccuments"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Kyc Doccuments</b></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm text">
              <tr class="table-wrapper-scroll-y tr-head rounded-circle">
                <th>+</th>
                <th>Name</th>
                <th>Action</th>
              </tr>

              <tbody>
                <tr style="color: #00364f">
                  <td>
                    <img style="width: 30px; height: 30px" :src="adharFrontImg" alt="" />
                  </td>

                  <td>Aadhar Front
                    <div>
                    <small v-if="masterDistributer.master_distributer_kyc">{{
                        masterDistributer.master_distributer_kyc.panNo
                      }}</small>
                  </div>
                  </td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycAdharFront.toLowerCase() == 'pdf'
                            ? openWindow(adharFrontImgPdf)
                            : openWindow(adharFrontImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img style="width: 30px; height: 30px" :src="adharBackImg" alt="" />
                  </td>

                  <td>Aadhar Back</td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycAdharBack.toLowerCase() == 'pdf'
                            ? openWindow(adharBackImgPdf)
                            : openWindow(adharBackImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img style="width: 30px; height: 30px" :src="panImg" alt="" />
                  </td>

                  <td>
                    Pan Card

                    <div>
                    <small v-if="masterDistributer.master_distributer_kyc">{{
                        masterDistributer.master_distributer_kyc.aadharNo
                      }}</small>
                  </div>
                  </td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                        extensionkycPan.toLowerCase() == 'pdf'
                            ? openWindow(panImgPdf)
                            : openWindow(panImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- </form> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn text-white btn-sm"
            data-bs-dismiss="modal"
            style="background-color: #f21300"
          >
            Close
          </button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>

  <!--start model  -->
  <div class="modal fade" id="walletModel" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-white">
          <h5 class="modal-title" id="exampleModalLabel" style="color: #00364f; font-size: 1.4vw">
            <b>Wallet </b>
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="
              walletEdit == 'false' ? walletCreateDone() : updatebeneficiaryAcount()
            "
          >
            <div class="row">
              <div class="col-12" v-if="walletForm.errors">
                <div v-if="walletForm.errors.message">
                  <div class="alert alert-danger" role="alert">
                    {{ walletForm.errors.message }}
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Mobile Number <small style="color:#f21000">*</small></label>

                <div class="from-group">
        
                    <input 
    required 
    type="tel" 
    minlength="10" 
    maxlength="10" 
    v-model="walletForm.mobile" 
    @input="handleInput" 
    class="form-control" 
    style="height: 30px" 
    placeholder="Mobile Number" 
  />


                </div>
                <small style="color: red" v-if="walletForm.errors"
                  ><span v-if="walletForm.errors.error"
                    ><span v-if="walletForm.errors.error.mobile">{{
                      walletForm.errors.error.mobile[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>UPI ID <small style="color:#f21000">*</small></label>

                <div class="from-group">
                  <input  required type="text" v-model="walletForm.merchant_vpa" class="form-control" style="height: 30px"
                    placeholder="UPI ID" @change="walletUpiVerify()" />

                </div>
                <small v-if="upiVerify"  >
               
                 <span v-if="upiVerify.account_exists == true" style="font-size: 8pt;color:green">
                  &nbsp; {{ upiVerify.name_at_bank }}
                 </span>
                 <span v-if="upiVerify.account_exists == false" style="font-size: 8pt;color:#f21000">
                  &nbsp; Please Enter Valid UPI
                 </span>

                </small>
                <small style="color: red" v-if="walletForm.errors"
                  ><span v-if="walletForm.errors.error"
                    ><span v-if="walletForm.errors.error.merchant_vpa">{{
                      walletForm.errors.error.merchant_vpa[0]
                    }}</span></span
                  ></small
                >
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-sm text-white" style="
                  background-color: #f21300;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                " data-bs-dismiss="modal">
                <b>Cancel</b>
              </button>
              <span v-if="upiVerify">
                      <button v-if="upiVerify.account_exists" type="submit" class="btn text-white btn-sm" style="
                  background-color: #00364f;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                ">
                <b>Confirm</b>
              </button>
              <button disabled v-else type="submit" class="btn text-white btn-sm" style="
                  background-color: #00364f;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                ">
                <b>Confirm</b>
              </button>
              </span>
        
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->

  <!--start wallet withdrawles model  -->
  <div
    class="modal fade"
    id="walletWithDrawModel"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-white">
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f; font-size: 1.4vw"
          >
            <b>Withdrawal </b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="
              withdrawalEdit == 'false'
                ? superadminWalletWithdrawal()
                : updatebeneficiaryAcount()
            "
          >
            <div class="row">
              <div class="col-12" v-if="walletwithdrawalForm.errors">
                <div v-if="walletwithdrawalForm.errors.message">
                  <div class="alert alert-danger" role="alert">
                    {{ walletwithdrawalForm.errors.message }}
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Amount <small style="color:red">*</small></label>

                <div class="from-group">
                  <input
                  required
                    type="number"
                    v-model="walletwithdrawalForm.amount"
                    class="form-control"
                    style="height: 30px"
                    placeholder="Amount"
                  />
                </div>
                <small style="color: red" v-if="walletwithdrawalForm.errors"
                  ><span v-if="walletwithdrawalForm.errors.error"
                    ><span v-if="walletwithdrawalForm.errors.error.amount">{{
                      walletwithdrawalForm.errors.error.amount[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Payment Mode <small style="color:red">*</small></label>
                <select
                required  
                  @change="paymantModeValueAssign()"
                  style="height: 30px; padding: 5px 5px 5px 5px"
                  v-model="walletwithdrawalForm.paymentMode"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option value="upi">UPI</option>
                  <option value="imps">IMPS</option>
                  <option value="neft">NEFT</option>
                  <option value="rtgs" disabled>RTGS</option>
                </select>
              </div>

              <div
                class="col-xl-6 col-md-6 col-12"
                style="padding: 5px 5px 5px 5px"
                v-if="walletwithdrawalForm.paymentMode != 'upi'"
              >
                <label>Beneficiaryaccount Account  <small style="color:red">*</small></label>
                <select
                  @change="paymantModeValueAssign()"
                  style="height: 30px; padding: 5px 5px 5px 5px"
                  v-model="walletwithdrawalForm.beneficiaryaccount_id"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option
                    v-for="(beneficiaryaccount, index) in beneficiaryaccounts"
                    :key="index"
                    :value="beneficiaryaccount.id"
                  >
                    {{ beneficiaryaccount.account_number }}
                  </option>
                </select>
              </div>
              <div
                class="col-xl-6 col-md-6 col-12"
                style="padding: 5px 5px 5px 5px"
                v-if="walletwithdrawalForm.paymentMode == 'upi'"
              >
                <label>UPI </label>

                <div class="from-group">
                  <input
                    type="text"
                    v-model="walletwithdrawalForm.merchant_vpa"
                    class="form-control"
                    style="height: 30px"
                    placeholder="UPI Number"
                  />
                </div>
                <small style="color: red" v-if="walletwithdrawalForm.errors"
                  ><span v-if="walletwithdrawalForm.errors.error"
                    ><span v-if="walletwithdrawalForm.errors.error.merchant_vpa">{{
                      walletwithdrawalForm.errors.error.merchant_vpa[0]
                    }}</span></span
                  ></small
                >
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="
                  background-color: #f21300;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                "
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn text-white btn-sm"
                style="
                  background-color: #00364f;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                "
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end wallet withdrawles   modal -->
  <!-- end wallet withdrawles   modal -->

  <!-- Modal Profile Upadate  -->
  <div
    class="modal fade"
    id="profileUpdateModel"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Profile Update</b></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Profile
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="businessdetails-tab"
                data-bs-toggle="tab"
                data-bs-target="#businessdetails"
                type="button"
                role="tab"
                aria-controls="businessdetails"
                aria-selected="false"
              >
              Business Details
              </button>
            </li>
            <li class="nav-item" role="presentation" v-if="masterDistributer.master_distributer_kyc">
              <button
              v-if="masterDistributer.master_distributer_kyc.kycStatus != 'approve'"
                class="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Kyc
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >

<div  v-if="msgImgError">
  <div class="alert alert-danger p-1" role="alert">
  {{msgImgError}}
</div>
</div>
            
              <form @submit.prevent="profileUpdate()">
                <div class="row">
                  <div class="col-12 col-md-12 col-xl-12" style="padding: 5px 5px 5px 5px">
                    <div>
                      <label for="profilePhoto">
                      <img
                        style="width: 70px; height: 70px"
                        :src="proUpdatePhoto"
                        alt=""
                      />
                    </label>
                    </div>
                  
                    <input
                    style="position: absolute;top: -150000000px;"
                      @change="uploadPhoto"
                      id="profilePhoto"
                      type="file"
                      class="form-control"
                      placeholder="Name"
                    />
                  </div>
                  <!-- <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <div>
                      <img
                        style="width: 100px; height: 100px"
                        :src="proUpdatelogo"
                        alt=""
                      />
                    </div>
                    <label>Logo</label>
                    <input
                      @change="uploadLogo"
                      type="file"
                      class="form-control"
                      id="profileLogo"
                      placeholder="Name"
                    />
                  </div> -->

                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>Name <small style="color:#f21000">*</small></label>
                    <input
                    required
                      v-model="updateProfileForm.name"
                      type="text"
                      class="form-control"
                      placeholder="Name"
                    />
                    <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.name">{{
                            updateProfileForm.errors.error.name[0]
                          }}</span></span
                        ></small
                      >
                  </div>

                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>Email   <small style="color:#f21000">*</small></label>
                    <input
                    required
                      type="email"
                      v-model="updateProfileForm.email"
                      class="form-control"
                      placeholder="Email"
                    />
                    <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.email">{{
                            updateProfileForm.errors.error.email[0]
                          }}</span></span
                        ></small
                      >
                  </div>

                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>Phone  <small style="color:#f21000">*</small></label>
                    <input
                    required
                      type="number"
                      v-model="updateProfileForm.phone"
                      class="form-control"
                      placeholder="Phone"
                      @change="lenthheker($event, 'phonePro')"
                    />
                    <span style="color: #f21000" id="phonePro"></span>
                    <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.phone">{{
                            updateProfileForm.errors.error.phone[0]
                          }}</span></span
                        ></small
                      >
                  </div>
                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>Alt Phone</label>

                    <input
                      type="number"
                      v-model="updateProfileForm.alternativePhone"
                      class="form-control"
                      placeholder="Alt Phone"
                      @change="lenthheker($event, 'altPhonePro')"
                    />
                    <span style="color: #f21000" id="altPhonePro"></span>

                    <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.alternativePhone">{{
                            updateProfileForm.errors.error.alternativePhone[0]
                          }}</span></span
                        ></small
                      >
                  </div>

                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>DOB  <small style="color:#f21000">*</small></label>
                    <input
                    required
                      type="date"
                      v-model="updateProfileForm.dob"
                      class="form-control"
                      placeholder="dob"
                     
                    />
                    <!-- @change="lenthheker($event, 'dobDate')" -->
                    <!-- <span style="color: #f21000" id="dobDate"></span> -->

                    <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.dob">{{
                            updateProfileForm.errors.error.dob[0]
                          }}</span></span
                        ></small
                      >
                  </div>
                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>Gender  <small style="color:#f21000">*</small></label>
                    <select
                    required
                      v-model="updateProfileForm.gender"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option selected disabled>select Gender...</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>State  <small style="color:#f21000">*</small></label>
                    <select
                    required
                      v-model="updateProfileForm.state_id"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option selected disabled>select...</option>
                      <option :value="sts.id" v-for="(sts, index) in states" :key="index">
                        {{ sts.name }}
                      </option>
                    </select>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>City</label>
                    <input
                      v-model="updateProfileForm.city"
                      type="text"
                      class="form-control"
                      placeholder="City"
                    />
                  </div>

                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>Pin  <small style="color:#f21000">*</small></label>

                    <input
                    required
                      type="number"
                      v-model="updateProfileForm.pin"
                      class="form-control"
                      placeholder="Pin"
                      @change="lenthheker($event, 'pinCode')"
                    />
                    <span style="color: #f21000" id="pinCode"></span>

                    <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.pin">{{
                            updateProfileForm.errors.error.pin[0]
                          }}</span></span
                        ></small
                      >
                  </div>
                  <div class="col-12 col-md-6 col-xl-6" style="padding: 5px 5px 5px 5px">
                    <label>Password</label>

                    <input
                      type="text"
                      v-model="updateProfileForm.password"
                      class="form-control"
                      placeholder="password"
                      
                    />
                            <!-- <span style="color: #f21000" id="proPassword"></span> -->
                            <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.password">{{
                            updateProfileForm.errors.error.password[0]
                          }}</span></span
                        ></small
                      >
                  </div>
                  <div
                    class="col-12 col-md-12 col-xl-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Address  <small style="color:#f21000">*</small></label>
                    <textarea
                    required
                      class="form-control"
                      rows="3"
                      v-model="updateProfileForm.address"
                      placeholder="Address"
                    ></textarea>
                    <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.address">{{
                            updateProfileForm.errors.error.address[0]
                          }}</span></span
                        ></small
                      >
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-sm text-white"
                    data-bs-dismiss="modal"
                    style="background-color: #f21000"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm text-white"
                    style="background-color: #00364f"
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
            <div
              class="tab-pane fade"
              id="businessdetails"
              role="tabpanel"
              aria-labelledby="businessdetails-tab"
            >
             
            <div class="accordion-body">
                      <form @submit.prevent="profileUpdate()">
                        <div class="row">
                          <div
                        class="col-12 col-md-6 col-xl-6"
                            style="padding: 5px 5px 5px 5px"
                          >
                            <!-- <label>Bussiness Pan<span style="color: #f21000">*</span></label>
                            <input
                              v-model="updateProfileForm.bussinessPan"
                              type="tel"
                              maxlength="10"
                              class="form-control"
                              placeholder="Bussiness Pan"
                              required
                            /> -->


                            <label>Bussiness Pan<span style="color: #f21000">*</span></label>
                  <span v-if="panVerifyForFirm == 'true'">
                    <input
                      disabled
                      v-model="updateProfileForm.bussinessPan"
                      type="tel"
                              maxlength="10"
                      class="form-control"
                      placeholder="Bussiness Pan"
                      id="bussinessPan"
                      
                      @change="bussinesPanVeryfied()"
                      @keydown="nameKeydown($event)"
                    />
                  </span>
                  <span v-else>
                    <input
                    maxlength="10"
                      v-model="updateProfileForm.bussinessPan"
                      type="text"
                      class="form-control"
                      placeholder="Bussiness Pan"
                      id="bussinessPan"
                      
                      @change="bussinesPanVeryfied()"
                      @keydown="nameKeydownbus($event)"
                    />
                  </span>

                  <small style="color: red" id="firmPanVeryfied"></small>
                  <small style="color: red" v-if="updateProfileForm.errors"
                    ><span v-if="updateProfileForm.errors.error"
                      ><span v-if="updateProfileForm.errors.error.bussinessPan">{{
                        updateProfileForm.errors.error.bussinessPan[0]
                      }}</span></span
                    ></small
                  >



                            
                          </div>

                          <div
                        class="col-12 col-md-6 col-xl-6"
                            style="padding: 5px 5px 5px 5px"
                          >
                            <label>Firm Name <span style="color: #f21000">*</span></label>
                            <input
                            
                              v-model="updateProfileForm.bussinessName"
                              type="text"
                              class="form-control"
                              placeholder="Firm Name"
                              required
                            />
                            <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.bussinessName">{{
                            updateProfileForm.errors.error.bussinessName[0]
                          }}</span></span
                        ></small
                      >
                          </div>

                          <div
                            class="col-12 col-md-6 col-xl-6"
                            style="padding: 5px 5px 5px 5px"
                          >
                            <label>Cin</label>
                            <input
                            
                              type="tel"
                              v-model="updateProfileForm.cin"
                              class="form-control"
                              placeholder="Cin"
                              @change="lenthheker($event, 'cinVal')"
                            />
                            <span style="color: #f21000" id="cinVal"></span>
                            <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.cin">{{
                            updateProfileForm.errors.error.cin[0]
                          }}</span></span
                        ></small
                      >
                          </div>

                          <div
                            class="col-12 col-md-6 col-xl-6"
                            style="padding: 5px 5px 5px 5px"
                          >
                            <label>Gstin <span style="color: #f21000">*</span></label>
                            <input
                            required
                            maxlength="15"
                              type="tel"
                              v-model="updateProfileForm.gstin"
                              class="form-control"
                              placeholder="gstin"
                              @change="lenthheker($event, 'gstinVal1')"
                            />
                            <span style="color: #f21000" id="gstinVal1"></span>
                            <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.gstin">{{
                            updateProfileForm.errors.error.gstin[0]
                          }}</span></span
                        ></small
                      >
                          </div>

                        

                          <div
                            class="col-12 col-md-12 col-xl-12"
                            style="padding: 5px 5px 5px 5px"
                          >
                            <label>Business State <span style="color: #f21000">*</span></label>
                            <select
                           
                            required
                              v-model="updateProfileForm.bussiness_state_id"
                              class="form-select"
                              aria-label="Default select example"
                            >
                              <option selected disabled>select...</option>
                              <option
                                :value="sts.id"
                                v-for="(sts, index) in states"
                                :key="index"
                              >
                                {{ sts.name }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="col-12 col-md-6 col-xl-6"
                            style="padding: 5px 5px 5px 5px"
                          >
                            <label>DOI <span style="color: #f21000">*</span></label>
                            <input
                            
                            required
                              type="date"
                              v-model="updateProfileForm.doi"
                              class="form-control"
                              placeholder="dob"
                            />
                            <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.doi">{{
                            updateProfileForm.errors.error.doi[0]
                          }}</span></span
                        ></small
                      >
                          </div>
                          <div
                            class="col-12 col-md-6 col-xl-6"
                            style="padding: 5px 5px 5px 5px"
                          >
                            <label>Business City <span style="color: #f21000">*</span></label>
                            <input
                            
                            required
                              v-model="updateProfileForm.bussinessCity"
                              type="text"
                              class="form-control"
                              placeholder="Business City"
                            />
                          </div>

                          <div
                            class="col-12 col-md-6 col-xl-6"
                            style="padding: 5px 5px 5px 5px"
                          >
                            <label>Pin <span style="color: #f21000">*</span></label>

                            <input
                            
                            required
                              type="number"
                              v-model="updateProfileForm.bussinessPin"
                              class="form-control"
                              placeholder="Pin"
                              @change="lenthheker($event, 'bussinessPinCode')"
                            />
                            <span style="color: #f21000" id="bussinessPinCode"></span>
                            <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.bussinessPin">{{
                            updateProfileForm.errors.error.bussinessPin[0]
                          }}</span></span
                        ></small
                      >
                          </div>
                          <div
                            class="col-12 col-md-6 col-xl-6"
                            style="padding: 5px 5px 5px 5px"
                          >
                            <label>TAN</label>

                            <input
                            
                              type="tel"
                              v-model="updateProfileForm.tds"
                              class="form-control"
                              placeholder="TAN"
                            />
                            <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.tds">{{
                            updateProfileForm.errors.error.tds[0]
                          }}</span></span
                        ></small
                      >
                          </div>

                          <div

                            class="col-12 col-md-12 col-xl-12"
                            style="padding: 5px 5px 5px 5px"
                          >
                            <label>Business Address <span style="color: #f21000">*</span></label>
                            <textarea
                            required
                              class="form-control"
                              rows="3"
                              v-model="updateProfileForm.bussinessAddress"
                              placeholder="Address"
                            ></textarea>
                            <small style="color: red" v-if="updateProfileForm.errors"
                        ><span v-if="updateProfileForm.errors.error"
                          ><span v-if="updateProfileForm.errors.error.bussinessAddress">{{
                            updateProfileForm.errors.error.bussinessAddress[0]
                          }}</span></span
                        ></small
                      >
                          </div>
                        </div>

                        <div class="modal-footer">
                          <button
                            type="submit"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f"
                          >
                            Confirm
                          </button>
                        </div>
                      </form>
                    </div>

             





            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div class="row">
                <div class="col-12">
                  <div
                    class="alert alert-danger p-1 mt-1"
                    role="alert"
                    v-if="msgImgError"
                  >
                    {{ msgImgError }}
                  </div>
                </div>
              </div>

              <form
                @submit.prevent="
                  editKyc == 'fasle' ? profilekycCreate() : profilekycUpdate()
                "
              >
                <div class="container-fluid table-scroll">
                  <table class="table table-hover table-sm">
                    <tr class="text table-wrapper-scroll-y rounded-circle tr-head">
                      <th class="text-truncate">+</th>
                      <th class="text-truncate">Document</th>
                      <th class="text-truncate">Action</th>
                    </tr>

                    <tbody class="text">
                      <tr>
                        <td>
                          <img
                            style="width: 30px; height: 30px"
                            :src="adharFrontImg"
                            alt=""
                          />
                        </td>
                        <td class="text-truncate">
                          <strong class="text">Aadhaar Front</strong>
                          <input
                            type="number"
                            maxlength="12"
                            required
                            v-model="formKyc.aadharNo"
                            class="form-control"
                            placeholder="Adhar Number"
                            @change="lenthheker($event, 'professionalKycAddar')"
                          />
                          <span style="color: #f21000" id="professionalKycAddar"></span>
                          <span style="color: #f21000" id="clientKycAddar"></span>
                          <small style="color: red" v-if="formKyc.errors"
                            ><span v-if="formKyc.errors.error"
                              ><span v-if="formKyc.errors.error.aadharNo">{{
                                formKyc.errors.error.aadharNo[0]
                              }}</span></span
                            ></small
                          >
                        </td>
                        <td class="text-truncate">
                          <label for="AdharFront">
                            <img
                              style="width: 25px; height: 25px"
                              src="/assets/image/upload.png"
                            />
                          </label>
                          <input
                            @change="uploadAdharFront"
                            class="input-file"
                            style="position: absolute; top: -150000px"
                            id="AdharFront"
                            type="file"
                            accept="image/*"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            style="width: 30px; height: 30px"
                            :src="adharBackImg"
                            alt=""
                          />
                        </td>
                        <td class="text-truncate">
                          <strong class="text">Aadhaar Back</strong>
                        </td>
                        <td class="text-truncate">
                          <label for="AdharBack">
                            <img
                              style="width: 25px; height: 25px"
                              src="/assets/image/upload.png"
                            />
                          </label>
                          <input
                            @change="uploadAdharBack"
                            class="input-file"
                            style="position: absolute; top: -150000px"
                            id="AdharBack"
                            type="file"
                            accept="image/*"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img style="width: 30px; height: 30px" :src="panImg" alt="" />
                        </td>
                        <td class="text-truncate">
                          <strong class="text">Pan</strong>
                          <input
                            v-if="panVerify == 'true'"
                            maxlength="10"
                            type="tel"
                            v-model="formKyc.panNo"
                            class="form-control"
                            placeholder="Pan Number"
                            disabled
                            @keydown.enter.prevent
                          />
                          <input
                            v-else
                            @keydown.enter.prevent
                            maxlength="10"
                            type="tel"
                            v-model="formKyc.panNo"
                            @change="panVeryfied()"
                            @keydown="nameKeydown($event)"
                            class="form-control"
                            placeholder="Pan Number"
                          />
                          <span style="color: #f21000" id="adminPanVeryfied"></span>
                          <small style="color: red" v-if="formKyc.errors"
                            ><span v-if="formKyc.errors.error"
                              ><span v-if="formKyc.errors.error.panNo">{{
                                formKyc.errors.error.panNo[0]
                              }}</span></span
                            ></small
                          >
                        </td>
                        <td class="text-truncate">
                          <label for="panphoto">
                            <img
                              style="width: 25px; height: 25px"
                              src="/assets/image/upload.png"
                            />
                          </label>
                          <input
                            @change="uploadPanPhoto"
                            class="input-file"
                            style="position: absolute; top: -150000px"
                            id="panphoto"
                            type="file"
                            accept="image/*"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-sm text-white"
                    style="background-color: #f21300"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="btn text-white btn-sm"
                    style="background-color: #00364f"
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import Spinner from "../../../components/master-distributer/comman/Spinner.vue";
import Banner from "../../../components/master-distributer/comman/Banner.vue";

import moment from "moment";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "MasterDistributerProfile",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      upiVerify:{},
      bankVerify:{},
      walletwithdrawalForm: {
        wallet_id: null,
        amount: null,
        paymentMode: "",
        beneficiaryaccount_id: null,
        beneficiaryaccount_type: "",
        merchant_vpa: "",
        remark: "",
        errors: {},
      },
      withdrawalEdit: "false",
      walletwithdrawalsReq: [],
      adharFrontImgPdf: "",
      adharBackImgPdf: "",
      panImgPdf: "",
      profileImg: this.$store.state.placeholderImg,
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      aadharFrontPdf: "",
      aadharBackPdf: "",
      passBookPdf: "",
      loading: false,
      services: [],
      wallet: {},
      beneficiaryaccounts: [],
      walletEdit: "false",
      walletForm: {
        merchant_vpa: "",
        mobile: "",
        errors: {},
      },
       qrForm: {
        amount: 100,
      },
      masterDistributer: {},
      form: {
        account_number: "",
        account_type: "saving",
        account_holder_name: "",
        ifsc_code: "",
        bank_name: "",
        bank_branch: "",
        mobile: "",
        status: "",
        remark: "",
        ipAddress: "",
        errors: {},
      },
      edit: "false",
      beneficiaryId: null,
      extensionkycProfilePhoto: "",
      extensionkycProfileLogo: "",
      proUpdatePhoto: this.$store.state.placeholderImg,
      proUpdatelogo: this.$store.state.placeholderImg,
      updateProfileForm: {
        address: "",
        alternativePhone: "",
        city: "",
        dob: "",
        email: "",
        gender: "",
        ipAddress: "",
        logo: "",
        photo: "",
        name: "",
        phone: "",
        state_id: null,
        password: "",
        pin: "",

        bussinessPan: "",
        bussinessName: "",
        bussinessAddress: "",
        bussinessCity: "",
        bussinessPin: "",
        bussiness_state_id: "",
        cin: "",
        gstin: "",

        errors : {}
      },
      msgImgError: "",
      editKyc: "fasle",
      formKyc: {
        panNo: null,
        panPhoto: "",
        accountType: "",
        accountNo: null,
        passbookPhoto: "",
        aadharNo: null,
        aadharFrontPhoto: "",
        aadharBackPhoto: "",
        remark: "",
        errors:{}
      },
      states: [],
      panVerify: "fasle",
      submitStatus: "fasle",
      professionalKycAddar: "fasle",

      panVerifyForFirm: "false",
      submitStatusForFirm: "false",

      
      
      benAccountNo:'false', 
      ifscCodeVal:'false',

      phonePro:'false',
      altPhonePro:'false',
      proPassword:'false',
      cinVal: "false",
      gstinVal1: "false",
      bussinessPinCode: "false",
    };
  },
  methods: {

    handleInput(event) {
      // Remove non-numeric characters from the input
      this.walletForm.mobile = event.target.value.replace(/\D/g, '');
    },
    walletUpiVerify(){
      this.upiVerify = {}
var form = {}
 
form.name = 'xcv';
form.virtual_payment_address = this.walletForm.merchant_vpa;
this.loading = true;
      this.$axios
        .post(
          "kyc/verify/upi",
          form,
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          this.upiVerify = res.data.data.data 
          console.log('============UPI=================');
          console.log(res.data.data.data);
        
        })
        .finally(() => (this.loading = false))

        .catch((error) => {

this.$swal.fire({
      position: "top-center",
      icon: "error",
      title: "<small>" + error.response.data.message + "</small>",
      showConfirmButton: true,
      confirmButtonColor: "#00364f",
    });



  });

    },

    benificiaryVerify(){

    

var form = {}

form.name = this.form.account_holder_name
form.account_number =   `${this.form.account_number}` 
form.ifsc = this.form.ifsc_code
form.mobile =   `${this.form.mobile}` 
console.log(form)
if(this.form.account_holder_name && this.form.account_number && this.form.ifsc_code && this.form.mobile){

  this.loading = true;
        this.$axios
          .post(`kyc/verify/bankaccount/pennyless`, form, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res.data;
            this.bankVerify = res.data.data.data
            console.log(res.data.data.data)
            if(this.bankVerify.account_exists == true){
               toast.success("verify Succesfully", {
              autoClose: 1000,
            });
          }
           

          })
          .finally(() => (this.loading = false))
          .catch((error) => {
                  this.$swal.fire({
                    position: "top-center",
                    icon: "error", 
                    text: "<small>"+error.response.data.message+"</small>",
                    showConfirmButton: true,
                    confirmButtonColor: "#00364f",
        
                  });

              });
}else{
  this.$swal.fire({
                    position: "top-center",
                    icon: "error", 
                    text: "Please Enter Value in all field.",
                    showConfirmButton: true,
                    confirmButtonColor: "#00364f",
        
                  });
}




          
    },
    withdrawalFormNull(){
   
this.walletwithdrawalForm.errors = {}
this.walletwithdrawalForm = {}
    },
    walletCreateNullForm(){
this.walletForm = {}
this.walletForm.errors = {}
this.walletAmount = "false";
          $("#walletAmount").html("<small></small>");

          this.phonePro = "false";
          $("#phonePro").html("<small></small>");

    },
    lenthheker(event, type) {
      var data = event.target.value;
          var forWallet = parseInt(data)
    var  amount  = parseInt(this.wallet.balanceAmount)

      if (type == "walletAmount") {
        if (forWallet > amount) {
          this.walletAmount = "true";

          $("#walletAmount").html("<small> You Have Unsefisian Balance</small>");
        }else{ 
           this.walletAmount = "false";
          $("#walletAmount").html("<small></small>");
        }
      }

      if (type == "dobDate") {

        var currentDate = new Date();
        data = this.dateTime(data)
        currentDate = this.dateTime(currentDate);
        if (data > currentDate) {
          this.dobDate = "true";

          $("#dobDate").html("<small> Enter Valid Date</small>");
        }else{ 
           this.dobDate = "false";
          $("#dobDate").html("<small></small>");
        }
      }




      // if (type == "phonePro") {
      //   if (data.length != 10) {
      //     this.phonePro = "true";
      //     $("#phonePro").html("<small>Phone Number 10 Digit Required</small>");
      //   }
      //   if (data.length == 10) {
      //     this.phonePro = "false";
      //     $("#phonePro").html("<small></small>");
      //   }
      // }

      if (type == "phonePro") {
        if (data.length != 10) {
          this.phonePro = "true";
          $("#phonePro").html("<small>Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          this.phonePro = "false";
          $("#phonePro").html("<small></small>");
        }
      }
      if (type == "altPhonePro") {
        if (data.length != 10) {
          if(data.length == 0){
            this.altPhonePro = "false";
          $("#altPhonePro").html("<small></small>");
          }else{
            this.altPhonePro = "true";
          $("#altPhonePro").html("<small>Alt Phone Number 10 Digit Required</small>");
          }
       
        }
        if (data.length == 10) {
          this.altPhonePro = "false";
          $("#altPhonePro").html("<small></small>");
        }
      }




      if (type == "pinCode") {
        if (data.length != 6) {
          this.pinCode = "true";
          $("#pinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          this.pinCode = "false";
          $("#pinCode").html("<small></small>");
        }
      }


      if (type == "proPassword") {
        if (data.length < 6) {
        
          if(data.length == 0){
            this.proPassword = "false";
          $("#proPassword").html("<small></small>");
          }else{
            this.proPassword = "true";
          $("#proPassword").html("<small>Password Minimum 6 Digits Required</small>");


          }
       
       
        }
        if (data.length >= 6) {
          this.proPassword = "false";
          $("#proPassword").html("<small></small>");
        }
      }




      if (type == "gstinVal1") {
        
        if (data.length != 15) { 

          if (data.length == 0) {
          this.gstinVal1 = "false";
          $("#gstinVal1").html("<small></small>");
        }else{
          this.gstinVal1 = "true";
          $("#gstinVal1").html("<small>GSTIN 15 Digit Required</small>");
        }
        }
        if (data.length == 15) {
          this.gstinVal1 = "false";
          $("#gstinVal1").html("<small></small>");
        }
      }

      if (type == "cinVal") {
        
        if (data.length != 21) {  if (data.length == 0) {
          this.cinVal = "false";
          $("#cinVal").html("<small></small>");
        }else{
          this.cinVal = "true";
          $("#cinVal").html("<small>CIN 21 Digit Required</small>");
        }
        }
        if (data.length == 21) {
          this.cinVal = "false";
          $("#cinVal").html("<small></small>");
        }
      }


      if (type == "bussinessPinCode") {
        if (data.length != 6) {
          this.bussinessPinCode = "true";
          $("#bussinessPinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          this.bussinessPinCode = "false";
          $("#bussinessPinCode").html("<small></small>");
        }
      }

            // kyc 

      if (type == "professionalKycAddar") {
        if (data.length != 12) {
          this.professionalKycAddar = "true";

          $("#professionalKycAddar").html(
            "<small>Aadhar required  12 Digits</small>"
          );
        }
        if (data.length == 12) {
          this.professionalKycAddar = "false";
          $("#professionalKycAddar").html("<small></small>");
        }
      }




      // benificiary 


      
      // if (type == "benAccountNo") {
   
      //   if (data.length < 9) {
      //     this.benAccountNo = "true";

      //     $("#benAccountNo").html(
      //       "<small>Account  Number required between 9-18 Digits</small>"
      //     );
      //   }else {
      //     if (data.length >18) {
      //     this.benAccountNo = "true";

      //     $("#benAccountNo").html(
      //       "<small>Account  Number required between 9-18 Digits</small>"
      //     );
      //   }else{
      //     this.benAccountNo = "false";
      //     $("#benAccountNo").html("<small></small>");
      //   }
         
      //   }
      // }
     
      
      // if (type == "ifscCodeVal") {
      //   if (data.length != 10) {
      //     this.ifscCodeVal = "true";
      //     $("#ifscCodeVal").html("<small>IFSC Required 10 Digits</small>");
      //   }
      //   if (data.length == 10) {
      //     this.ifscCodeVal = "false";
      //     $("#ifscCodeVal").html("<small></small>");
      //   }
      // }


       // benificiary 


      
       if (type == "benAccountNo") {
   
   if (data.length < 9) {
     this.benAccountNo = "true";

     $("#benAccountNo").html(
       "<small>Account  Number required between 9-18 Digits</small>"
     );
   }else {
     if (data.length >18) {
     this.benAccountNo = "true";

     $("#benAccountNo").html(
       "<small>Account  Number required between 9-18 Digits</small>"
     );
   }else{
     this.benAccountNo = "false";
     $("#benAccountNo").html("<small></small>");
   }
    
   }
 }

 
 if (type == "ifscCodeVal") {
   if (data.length != 11) {
     this.ifscCodeVal = "true";
     $("#ifscCodeVal").html("<small>IFSC Required 11 Digits</small>");
   }
   if (data.length == 11) {
     this.ifscCodeVal = "false";
     $("#ifscCodeVal").html("<small></small>");
   }
 }


    },
    nameKeydownbus(e) {
      if (/^\W$/.test(e.key)) {
        $("#firmPanVeryfied").html("<small>special characters are not allowed</small>");
        e.preventDefault();
      } else {
        $("#firmPanVeryfied").html("<small></small>");
      }
    },
    bussinesPanVeryfied() {
      var data = this.updateProfileForm.bussinessPan;
      if (data.length == 10) {
        this.submitStatusForFirm = "false";
        $("#firmPanVeryfied").html("<small></small>");
        // this.msgPanError = "";
        this.loading = true;
        this.$axios
          .post(
            "masterdistributer/verifypan",
            { panNo: this.updateProfileForm.bussinessPan },
            { headers: { Authorization: "Bearer " + localStorage.accessToken } }
          )
          .then((res) => {
            console.log(res.data.data);
            if (res.data.data.message) {
              this.submitStatusForFirm = "true";
              $("#firmPanVeryfied").html("<small>" + res.data.data.message + "</small>");
            }
            if (res.data.data.data) {
              $("#firmPanVeryfied").html(
                "<small>" + res.data.data.data.status + " Pan </small>"
              );
            }
            if (res.data.data.data.status == "VALID") {
              this.submitStatusForFirm = "false";
              $("#firmPanVeryfied").html("<small></small>");
              this.panVerifyForFirm = "true";
              toast.success("Pan Verified Succesfull", {
                autoClose: 1000,
              });
            } else {
              this.submitStatusForFirm = "true";
            }
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
      } else {
        this.submitStatusForFirm == "true";
        $("#firmPanVeryfied").html("<small> Pan required 10 Digits</small>");
      }

      // this.panNo = ''
    },
    panVeryfied() {
      var data = this.formKyc.panNo;
      if (data.length == 10) {
        this.submitStatus = "false";
        $("#adminPanVeryfied").html("<small></small>");
        // this.msgPanError = "";
        this.loading = true;
        this.$axios
          .post(
            "masterdistributer/verifypan",
            { panNo: this.formKyc.panNo },
            { headers: { Authorization: "Bearer " + localStorage.accessToken } }
          )
          .then((res) => {
            if (res.data.data.message) {
              // this.msgPanError = res.data.data.message;
              this.submitStatus = "true";
              $("#adminPanVeryfied").html("<small>" + res.data.data.message + "</small>");
            }
            if (res.data.data.data) {
              $("#adminPanVeryfied").html(
                "<small>" + res.data.data.data.status + " Pan </small>"
              );
            }
            if (res.data.data.data.full_name) {
              this.submitStatus = "false";
              $("#adminPanVeryfied").html("<small></small>");
              this.panVerify = "true";
             
              toast.success("Pan Verified Succesfull", {
                autoClose: 1000,
              });



            }
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.submitStatus = "true";
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
      } else {
        this.submitStatus = "true";
        $("#adminPanVeryfied").html("<small> Pan required 10 Digits</small>");
      }

      // this.panNo = ''
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        $("#adminPanVeryfied").html("<small>special characters are not allowed</small>");
        e.preventDefault();
      } else {
        $("#adminPanVeryfied").html("<small></small>");
      }
    },
    uploadAdharFront(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Aadhar Front File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#AdharFront").value;
        this.extensionkycAdharFront = fileName.split(".").pop();

        if (
          this.extensionkycAdharFront == "pdf" ||
          this.extensionkycAdharFront == "jpg" ||
          this.extensionkycAdharFront == "jpeg" ||
          this.extensionkycAdharFront == "png"  ||
          this.extensionkycAdharFront == "PDF" ||
          this.extensionkycAdharFront == "JPG" ||
          this.extensionkycAdharFront == "JPEG" ||
          this.extensionkycAdharFront == "PNG"
        ) {
          this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
          this.formKyc.aadharFrontPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG ,JPEG , PNG , PDF ";
        }

        if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      }
    },
    uploadAdharBack(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Aadhar Back  File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#AdharBack").value;
        this.extensionkycAdharBack = fileName.split(".").pop();

        if (
          this.extensionkycAdharBack == "pdf" ||
          this.extensionkycAdharBack == "jpg" ||
          this.extensionkycAdharBack == "jpeg" ||
          this.extensionkycAdharBack == "png" ||
          this.extensionkycAdharBack == "PDF" ||
          this.extensionkycAdharBack == "JPG" ||
          this.extensionkycAdharBack == "JPEG" ||
          this.extensionkycAdharBack == "PNG"
        ) {
          this.adharBackImg = URL.createObjectURL(e.target.files["0"]);
          this.formKyc.aadharBackPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG , PNG , PDF, JPEG ";
        }

        if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      }
    },

    uploadPanPhoto(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Pan File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#panphoto").value;
        this.extensionkycPan = fileName.split(".").pop();

        if (
          this.extensionkycPan == "pdf" ||
          this.extensionkycPan == "jpg" ||
          this.extensionkycPan == "jpeg" ||
          this.extensionkycPan == "png"  ||
          this.extensionkycPan == "PDF" ||
          this.extensionkycPan == "JPG" ||
          this.extensionkycPan == "JPEG" ||
          this.extensionkycPan == "PNG"
        ) {
          this.panImg = URL.createObjectURL(e.target.files["0"]);
          this.formKyc.panPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG , PNG , PDF, JPEG ";
        }

        if (this.extensionkycPan == "pdf" || this.extensionkycPan == "PDF") {
          this.panImg = "/assets/image/pdf.png";
        }
      }
    },
    formnull(){
      this.bankVerify = {}
      this.form={}
      this.form.errors = {}
      this.ifscCodeVal = "false";
     $("#ifscCodeVal").html("<small></small>");
     this.benAccountNo = "false";
     $("#benAccountNo").html("<small></small>");
     this.phonePro = "false";
          $("#phonePro").html("<small></small>");
     
    },

    valueAssingForProfile() {
      this.formKyc = {}
      this.submitStatus = "false";
        $("#adminPanVeryfied").html("<small></small>");

      this.proPassword = "false";
          $("#proPassword").html("<small></small>");
          this.pinCode = "false";
          $("#pinCode").html("<small></small>");
          this.altPhonePro = "false";
          $("#altPhonePro").html("<small></small>");
          this.phonePro = "false";
          $("#phonePro").html("<small></small>");
          this.dobDate = "false";
          $("#dobDate").html("<small></small>");
          this.professionalKycAddar = "false";
          $("#professionalKycAddar").html("<small></small>"); 


      this.msgImgError=''
      this.updateProfileForm={} 
      this.updateProfileForm.errors={} 


      this.updateProfileForm.name = this.masterDistributer.name;
      this.updateProfileForm.phone = this.masterDistributer.phone;
      this.updateProfileForm.address = this.masterDistributer.address;
     
      this.updateProfileForm.city = this.masterDistributer.city;
      this.updateProfileForm.email = this.masterDistributer.email;
      this.updateProfileForm.gender = this.masterDistributer.gender;
      this.updateProfileForm.state_id = this.masterDistributer.state_id;
      this.updateProfileForm.pin = this.masterDistributer.pin;
      this.updateProfileForm.dob = this.masterDistributer.dob;

      // bussiness Deatils 



      this.updateProfileForm.bussinessPan = this.masterDistributer.bussinessPan;
      this.updateProfileForm.bussinessName = this.masterDistributer.bussinessName;
      this.updateProfileForm.bussinessAddress = this.masterDistributer.bussinessAddress;
      this.updateProfileForm.bussinessCity = this.masterDistributer.bussinessCity;
      this.updateProfileForm.bussinessPin = this.masterDistributer.bussinessPin;
      this.updateProfileForm.bussiness_state_id = this.masterDistributer.bussiness_state_id;
      this.updateProfileForm.cin = this.masterDistributer.cin;
      this.updateProfileForm.gstin = this.masterDistributer.gstin;
      this.updateProfileForm.tds = this.masterDistributer.tds;
      this.updateProfileForm.doi = this.masterDistributer.doi;

      
      if(this.masterDistributer.bussinessPan){
        this.panVerifyForFirm = 'true'
      }else{
        this.panVerifyForFirm = 'false'
      }


      if(this.masterDistributer.alternativePhone){
        this.updateProfileForm.alternativePhone = this.masterDistributer.alternativePhone;
      }else{
        this.updateProfileForm.alternativePhone = "";
      }



      if (this.masterDistributer.logo) {
        this.proUpdatelogo =
          this.$store.state.imgUrl +
          "/masterdistributer/" +
          this.masterDistributer.id +
          "/thumbs/" +
          this.masterDistributer.logo;
      } else {
        this.proUpdatelogo = this.$store.state.placeholderImg;
      }

      if (this.masterDistributer.photo) {
        this.proUpdatePhoto =
          this.$store.state.imgUrl +
          "/masterdistributer/" +
          this.masterDistributer.id +
          "/thumbs/" +
          this.masterDistributer.photo;
      } else {
        this.proUpdatePhoto = this.$store.state.placeholderImg;
      }

      // kyc

      this.adharFrontImg = this.$store.state.placeholderImg;
      this.adharBackImg = this.$store.state.placeholderImg;
      this.panImg = this.$store.state.placeholderImg;

      if (this.masterDistributer.master_distributer_kyc) {
        this.formKyc.panNo = this.masterDistributer.master_distributer_kyc.panNo;
        this.editKyc = "true";
        this.panVerify = "true";

        this.formKyc.aadharNo = this.masterDistributer.master_distributer_kyc.aadharNo;

        if (this.masterDistributer.master_distributer_kyc) {
        
          if (
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            this.masterDistributer.id +
            "/thumbs/" +
            this.masterDistributer.master_distributer_kyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/thumbs/" +
              this.masterDistributer.master_distributer_kyc.panPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            this.masterDistributer.id +
            "/" +
            this.masterDistributer.master_distributer_kyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/" +
              this.masterDistributer.master_distributer_kyc.panPhoto;
          }
          console.log('=========================')
          console.log(this.panImg)
          // alert(this.panImg)
          
          this.extensionkycPan = this.panImg.split(".").pop();

          if (this.extensionkycPan == "pdf"  || this.extensionkycPan == "PDF") {
            this.panImgPdf = this.panImg
            this.panImg = "/assets/image/pdf.png";
          }
        } else {
          this.panImg = this.$store.state.placeholderImg;
        }







        // aadhar front

        if (this.masterDistributer.master_distributer_kyc.aadharFrontPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            this.masterDistributer.id +
            "/thumbs/"+
            this.masterDistributer.master_distributer_kyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/thumbs/" +
              this.masterDistributer.master_distributer_kyc.aadharFrontPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            this.masterDistributer.id +
            "/" +
            this.masterDistributer.master_distributer_kyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/" +
              this.masterDistributer.master_distributer_kyc.aadharFrontPhoto;
          }
          console.log(this.adharFrontImg)
          this.adharFrontImgPdf = this.adharFrontImg
          this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

          if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
            this.adharFrontImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharFrontImg = this.$store.state.placeholderImg;
        }



// aadhar Back photo
        if (this.masterDistributer.master_distributer_kyc.aadharBackPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            this.masterDistributer.id +
            "/thumbs/"+
            this.masterDistributer.master_distributer_kyc.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/thumbs/" +
              this.masterDistributer.master_distributer_kyc.aadharBackPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            this.masterDistributer.id +
            "/" +
            this.masterDistributer.master_distributer_kyc.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/" +
              this.masterDistributer.master_distributer_kyc.aadharBackPhoto;
          }
          console.log(this.adharBackImg)
          this.adharBackImgPdf = this.adharBackImg
          this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

          if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
            this.adharBackImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharBackImg = this.$store.state.placeholderImg;
        }

// Pan photo
if (this.masterDistributer.master_distributer_kyc.panPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            this.masterDistributer.id +
            "/thumbs/"+
            this.masterDistributer.master_distributer_kyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/thumbs/" +
              this.masterDistributer.master_distributer_kyc.panPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/masterdistributer/" +
            this.masterDistributer.id +
            "/" +
            this.masterDistributer.master_distributer_kyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/" +
              this.masterDistributer.master_distributer_kyc.panPhoto;
          }
          console.log(this.panImg)
          this.panImgPdf = this.panImg
          this.extensionkycPan = this.panImg.split(".").pop();

          if (this.extensionkycPan == "pdf" || this.extensionkycPan == "PDF") {
            this.panImg = "/assets/image/pdf.png";
          }
        } else {
          this.panImg = this.$store.state.placeholderImg;
        }

        
      
      }
    },
    uploadPhoto(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Profile Photo Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#profilePhoto").value;
        this.extensionkycProfilePhoto = fileName.split(".").pop();

        if (
          this.extensionkycProfilePhoto == "jpg" ||
          this.extensionkycProfilePhoto == "jpeg" ||
          this.extensionkycProfilePhoto == "png" ||
          this.extensionkycProfilePhoto == "JPG" ||
          this.extensionkycProfilePhoto == "JPEG" ||
          this.extensionkycProfilePhoto == "PNG"
        ) {
          this.proUpdatePhoto = URL.createObjectURL(e.target.files["0"]);
          this.updateProfileForm.photo = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG ,JPEG, PNG  ";
        }
      }
    },

    uploadLogo(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Logo Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#profileLogo").value;
        this.extensionkycProfileLogo = fileName.split(".").pop();

        if (
          this.extensionkycProfileLogo == "jpg" ||
          this.extensionkycProfileLogo == "jpeg" ||
          this.extensionkycProfileLogo == "png"   ||
          this.extensionkycProfileLogo == "JPG" ||
          this.extensionkycProfileLogo == "JPEG" ||
          this.extensionkycProfileLogo == "PNG"
        ) {
          this.proUpdatelogo = URL.createObjectURL(e.target.files["0"]);
          this.updateProfileForm.logo = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG ,JPEG, PNG  ";
        }
      }
    },
    loadState() {
      this.$axios
        .get("masterdistributer/state?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data)
          this.states = res.data.data;
          // console.log(this.states)
        });
    },
    profileUpdate() {
      if(this.phonePro == 'false' && this.altPhonePro == 'false' && this.bussinessPinCode == 'false' && this.gstinVal1 == 'false' && this.cinVal == 'false' && this.pinCode == 'false' ){
        const formData = new FormData();
      const keys = Object.keys(this.updateProfileForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.updateProfileForm[keys[n]] !== null) {
          formData.append(keys[n], this.updateProfileForm[keys[n]]);
        }
      }

      this.loading = true;
      this.$axios
        .post(`masterdistributer/profile?method=PUT`, formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Updated Succesfully", {
            autoClose: 1000,
          });
          $("#profileUpdateModel").modal("hide");
          this.updateProfileForm = {};
          this.editKyc = "false";
          this.loadMasterDistributer();
          window.location.reload();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {

this.updateProfileForm.errors=error.response.data
});
// }else{

//   this.$swal.fire({
//             confirmButtonColor: "#00364f",
//             icon: "error",
//             title: "Oops...",
//             text: 'Please Enter The Valid Information.',
//           });
// }

//     },

      }
     
    },

    profilekycUpdate() {
      if(this.submitStatus == 'false' && this.professionalKycAddar == 'false' && this.msgImgError == ''){
        
        const formData = new FormData();
      const keys = Object.keys(this.formKyc);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.formKyc[keys[n]] !== null) {
          formData.append(keys[n], this.formKyc[keys[n]]);
        }
      }

      var kycId = null;
      if (this.masterDistributer.master_distributer_kyc) {
        kycId = this.masterDistributer.master_distributer_kyc.id;
      }
      this.loading = true;
      this.$axios
        .post(`masterdistributer/masterdistributerkyc/${kycId}?_method=PUT`, formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Updated Succesfully", {
            autoClose: 1000,
          });
          $("#profileUpdateModel").modal("hide");
          this.editKyc = "false";
          this.formKyc = {};
          this.loadMasterDistributer();
          // window.location.reload();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {

this.formKyc.errors = error.response.data


})
        
      }
      
    },

    profilekycCreate() {
      if(this.submitStatus == 'false' && this.professionalKycAddar == 'false' && this.msgImgError == ''){

        const formData = new FormData();
      const keys = Object.keys(this.formKyc);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.formKyc[keys[n]] !== null) {
          formData.append(keys[n], this.formKyc[keys[n]]);
        }
      }

  
        this.loading = true;
      this.$axios
        .post(`masterdistributer/masterdistributerkyc`, formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Created Succesfully", {
            autoClose: 1000,
          });
          $("#profileUpdateModel").modal("hide");
          this.formKyc = {};
          this.loadMasterDistributer();
          // window.location.reload();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {

this.formKyc.errors = error.response.data


})

      }
     
    
    },

    // end profile update
    paymantModeValueAssign() {
      if (this.walletwithdrawalForm.paymentMode == "upi") {
        this.walletwithdrawalForm.merchant_vpa = this.wallet.merchant_vpa;
        this.walletwithdrawalForm.beneficiaryaccount_type = "";
      } else {
        this.walletwithdrawalForm.merchant_vpa = "";
        this.walletwithdrawalForm.beneficiaryaccount_type =
          "masterDistributerBeneficiaryAccount";
      }
    },
    superadminWalletWithdrawalGet() {
      // this.walletwithdrawalForm.wallet_id = this.wallet.id;
      this.loading = true;
      this.$axios
        .get("masterdistributer/walletwithdrawal?per_page=5", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log("Req =====");
          console.log(res.data.data.data);
          console.log("Req =====");

          this.walletwithdrawalsReq = res.data.data.data;
        })
        .catch((error) => {
          this.walletwithdrawalForm.errors = error.response.data;
        })
        .finally(() => (this.loading = false));
    },
    superadminWalletWithdrawal() {
      this.walletwithdrawalForm.wallet_id = this.wallet.id;
      this.loading = true;
      this.$axios
        .post("masterdistributer/walletwithdrawal", this.walletwithdrawalForm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Request Sent successfully", {
            autoClose: 1000,
          });
          this.walletwithdrawalForm = {};
          $("#walletWithDrawModel").modal("hide");
          this.superadminWalletWithdrawalGet();
        })
        .catch((error) => {
          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: error.response.data.error,
          });
        })
        .finally(() => (this.loading = false));
    },

    walletWithdrawReqCancel(value) {
      

      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Cancel it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, Cancel it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
      this.loading = true;
      this.$axios
        .post(
          `masterdistributer/walletwithdrawal/cancel/${value.id}`,
          {},
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          console.log(res.data);

          toast.success("Request Canceled  successfully", {
            autoClose: 1000,
          });

          this.superadminWalletWithdrawalGet();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
                this.$swal.fire({
                  position: "top-center",
                  icon: "error",
                  title: "<small>" + error.response.data.error + "</small>",
                  showConfirmButton: true,
                  confirmButtonColor: "#00364f",
                });
              });
          }
        });


    },

    loadBeneficiaryAccount() {
      this.$axios
        .get("masterdistributer/beneficiaryaccount", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.beneficiaryaccounts = res.data.data.data;
        });
    },

    editBeneficiaryAcount(acount) {
      this.form_id = acount.id;
      this.beneficiaryId = acount.id;

      this.form = acount;
      this.edit = "true";
    },
    updatebeneficiaryAcount() {
      this.$axios
        .put(`masterdistributer/beneficiaryaccount/${this.beneficiaryId}`, this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;

          toast.success("Beneficiary Account Updated successfully", {
            autoClose: 1000,
          });

          this.form = {};
          this.form_id = null;
          this.beneficiary_id = null;
          this.edit = "false";
          $("#exampleModal").modal("hide");
          this.loadBeneficiaryAccount();
        });
    },

    beneficiaryaccountAdd() {
      if(this.phonePro == 'false' &&  this.ifscCodeVal == 'false' &&  this.benAccountNo == 'false' ){
        this.loading = true;
        this.$axios
        .post("masterdistributer/beneficiaryaccount", this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Beneficiary Account Created successfully", {
            autoClose: 1000,
          });
          this.form = {};
          this.form_id = null;
          this.beneficiary_id = null;
          this.edit = "false";
          $("#exampleModal").modal("hide");
          this.loadBeneficiaryAccount();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // console.log(error.response);
          this.form.errors = error.response.data;
          // console.log(this.form.errors);
          //  alert(this.form.errors.error)
        });
      }

     
    },
    openWindow(url) {
      window.open(`${url}`);
    },
    loadWallet() {
      this.$axios
        .get("masterdistributer/wallet", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          this.wallet = res.data.data;
        });
    },

    walletCreateDone() {
      this.loading = true;
      this.$axios
        .post("masterdistributer/wallet", this.walletForm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          toast.success("Wallet Created Succesfully", {
            autoClose: 1000,
          });
          $("#walletModel").modal("hide");
          this.loadWallet();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {

this.walletForm.errors=error.response.data
});
    },

    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm ");
      }
      
    },
    downloadPdf(fileName) {
      window.open(fileName);
    },

    distributerWallet() {
      this.$axios
        .get("masterdistributer/wallet", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          this.wallet = res.data.data;
        });
    },
    distributerBeneficiaryaccount() {
      this.$axios
        .get("masterdistributer/beneficiaryaccount", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(
            "__________beneficiaryaccount_________________________________________"
          );
          console.log(res.data.data.data);
          this.beneficiaryaccounts = res.data.data.data;
        });
    },
    loadMasterDistributer() {
      this.loading = true;
      this.$axios
        .post(
          "masterdistributer/user",
          {},
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          // console.log(res.data);
          this.masterDistributer = res.data;

          if (this.masterDistributer.photo != null) {
            if (
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/" +
              this.masterDistributer.photo
            ) {
              this.profileImg =
                this.$store.state.imgUrl +
                "/masterdistributer/" +
                this.masterDistributer.id +
                "/" +
                this.masterDistributer.photo;
            }
            if (
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/thumbs/" +
              this.masterDistributer.photo
            ) {
              this.profileImg =
                this.$store.state.imgUrl +
                "/masterdistributer/" +
                this.masterDistributer.id +
                "/thumbs/" +
                this.masterDistributer.photo;
              //   alert(this.aadharFrontPdf)
            }
          } else {
            this.adharFrontImg = this.$store.state.placeholderImg;
          }
          if (this.masterDistributer.master_distributer_kyc.aadharFrontPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/thumbs/" +
              this.masterDistributer.master_distributer_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/masterdistributer/" +
                this.masterDistributer.id +
                "/thumbs/" +
                this.masterDistributer.master_distributer_kyc.aadharFrontPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/" +
              this.masterDistributer.master_distributer_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/masterdistributer/" +
                this.masterDistributer.id +
                "/" +
                this.masterDistributer.master_distributer_kyc.aadharFrontPhoto;
            }

            this.adharFrontImgPdf = this.adharFrontImg;
            this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

            if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
              this.adharFrontImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharFrontImg = this.$store.state.placeholderImg;
          }

          if (this.masterDistributer.master_distributer_kyc.aadharBackPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/thumbs/" +
              this.masterDistributer.master_distributer_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/masterdistributer/" +
                this.masterDistributer.id +
                "/thumbs/" +
                this.masterDistributer.master_distributer_kyc.aadharBackPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/" +
              this.masterDistributer.master_distributer_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/masterdistributer/" +
                this.masterDistributer.id +
                "/" +
                this.masterDistributer.master_distributer_kyc.aadharBackPhoto;
            }

            this.adharBackImgPdf = this.adharBackImg;
            this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

            if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
              this.adharBackImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharBackImg = this.$store.state.placeholderImg;
          }

          if (this.masterDistributer.master_distributer_kyc.panPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/thumbs/" +
              this.masterDistributer.master_distributer_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/masterdistributer/" +
                this.masterDistributer.id +
                "/thumbs/" +
                this.masterDistributer.master_distributer_kyc.panPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/masterdistributer/" +
              this.masterDistributer.id +
              "/" +
              this.masterDistributer.master_distributer_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/masterdistributer/" +
                this.masterDistributer.id +
                "/" +
                this.masterDistributer.master_distributer_kyc.panPhoto;
            }

            this.panImgPdf = this.panImg;
            this.extensionkycPan = this.panImg.split(".").pop();

            if (this.extensionkycPan == "pdf" || this.extensionkycPan == "PDF") {
              // alert(this.extensionkycPan)
              this.extensionkycPan  = this.extensionkycPan.toLowerCase()
              // alert(this.extensionkycPan)
              this.panImg = "/assets/image/pdf.png";
            }
          } else {
            this.panImg = this.$store.state.placeholderImg;
          }
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.masterDistributerPageTitles.profile
    console.log(this.$store.state.masterDistributerPageTitles.profile)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadMasterDistributer();
      this.loadWallet();
      this.superadminWalletWithdrawalGet();
      this.loadState();
      this.loadBeneficiaryAccount();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
